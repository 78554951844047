module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-EQ6X9LYQDE"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.30","publicApiKey":"ODkxYzMzMjctZWU1Mi00NTk1LTliNWEtOTgxMDY2NDg2ZjVkNjM3NDA2MzYxMTA2NTU1NDUx","defaultLang":"en","provideDefaultCurrency":false,"currency":"gbp","openCartOnAdd":false,"templatesUrl":"/snipcart/index.js","locales":{"en":{"payment":{"form":{"card_label":"Debit or Credit Card"}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"zuckermaus-bakery","short_name":"zuckermaus","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/zuckermaus-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c1c840e944db1f82636b13cab6ff1147"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
